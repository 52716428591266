//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { crossComponentTranslator } from 'kolibri.utils.i18n';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import KLabeledIcon from 'kolibri.coreVue.components.KLabeledIcon';
import find from 'lodash/find';
import pickBy from 'lodash/pickBy';
import themeMixin from 'kolibri.coreVue.mixins.themeMixin';
import responsiveWindow from 'kolibri.coreVue.mixins.responsiveWindow';
import { validateUsername } from 'kolibri.utils.validators';
import KButton from 'kolibri.coreVue.components.KButton';
import KTextbox from 'kolibri.coreVue.components.KTextbox';
import PointsIcon from 'kolibri.coreVue.components.PointsIcon';
import PermissionsIcon from 'kolibri.coreVue.components.PermissionsIcon';
import UserTypeDisplay from 'kolibri.coreVue.components.UserTypeDisplay';
import UiAlert from 'keen-ui/src/UiAlert';
import { PermissionTypes, ERROR_CONSTANTS } from 'kolibri.coreVue.vuex.constants';
import SignUpPage from '../SignUpPage';
import ChangeUserPasswordModal from './ChangeUserPasswordModal';

const SignUpPageStrings = crossComponentTranslator(SignUpPage);

export default {
  name: 'ProfilePage',
  $trs: {
    success: 'Profile details updated',
    username: 'Username',
    name: 'Full name',
    updateProfile: 'Save changes',
    isSuperuser: 'Super admin permissions ',
    manageContent: 'Manage content',
    manageDevicePermissions: 'Manage device permissions',
    points: 'Points',
    userType: 'User type',
    devicePermissions: 'Device permissions',
    usernameNotAlphaNumUnderscore: 'Username can only contain letters, numbers, and underscores',
    required: 'This field is required',
    limitedPermissions: 'Limited permissions',
    youCan: 'You can',
    changePasswordPrompt: 'Change password',
    usernameAlreadyExists: 'An account with that username already exists',
    documentTitle: 'User Profile',
  },
  metaInfo() {
    return {
      title: this.$tr('documentTitle'),
    };
  },
  components: {
    KButton,
    KTextbox,
    KLabeledIcon,
    UiAlert,
    PointsIcon,
    PermissionsIcon,
    ChangeUserPasswordModal,
    UserTypeDisplay,
  },
  mixins: [responsiveWindow, themeMixin],
  data() {
    return {
      username: this.$store.state.core.session.username,
      name: this.$store.state.core.session.full_name,
      usernameBlurred: false,
      nameBlurred: false,
      formSubmitted: false,
    };
  },
  computed: {
    ...mapGetters([
      'facilityConfig',
      'getUserKind',
      'getUserPermissions',
      'isCoach',
      'isLearner',
      'isSuperuser',
      'totalPoints',
      'userHasPermissions',
    ]),
    ...mapState({
      session: state => state.core.session,
    }),
    ...mapState('profile', ['busy', 'errorCode', 'passwordState', 'success']),
    ...mapState('profile', {
      profileErrors: 'errors',
    }),
    userPermissions() {
      return pickBy(this.getUserPermissions);
    },
    facilityString() {
      return SignUpPageStrings.$tr('facility');
    },
    facilityName() {
      const match = find(this.$store.getters.facilities, {
        id: this.$store.getters.currentFacilityId,
      });
      return match ? match.name : '';
    },
    passwordModalVisible() {
      return this.passwordState.modal;
    },
    permissionType() {
      if (this.isSuperuser) {
        return PermissionTypes.SUPERUSER;
      } else if (this.userHasPermissions) {
        return PermissionTypes.LIMITED_PERMISSIONS;
      }
      return null;
    },
    permissionTypeText() {
      if (this.isSuperuser) {
        return this.$tr('isSuperuser');
      } else if (this.userHasPermissions) {
        return this.$tr('limitedPermissions');
      }
      return '';
    },
    canEditUsername() {
      if (this.isCoach || this.isLearner) {
        return this.facilityConfig.learnerCanEditUsername;
      }
      return true;
    },
    canEditName() {
      if (this.isCoach || this.isLearner) {
        return this.facilityConfig.learnerCanEditName;
      }
      return true;
    },
    canEditPassword() {
      return this.isSuperuser || this.facilityConfig.learnerCanEditPassword;
    },
    nameIsInvalidText() {
      if (this.nameBlurred || this.formSubmitted) {
        if (this.name === '') {
          return this.$tr('required');
        }
      }
      return '';
    },
    nameIsInvalid() {
      return Boolean(this.nameIsInvalidText);
    },
    usernameIsInvalidText() {
      if (this.usernameBlurred || this.formSubmitted) {
        if (this.username === '') {
          return this.$tr('required');
        }
        if (!validateUsername(this.username)) {
          return this.$tr('usernameNotAlphaNumUnderscore');
        }
        if (this.usernameAlreadyExists) {
          return this.$tr('usernameAlreadyExists');
        }
      }
      return '';
    },
    usernameIsInvalid() {
      return Boolean(this.usernameIsInvalidText);
    },
    usernameAlreadyExists() {
      return this.profileErrors.includes(ERROR_CONSTANTS.USERNAME_ALREADY_EXISTS);
    },
    formIsValid() {
      return !this.usernameIsInvalid;
    },
  },
  created() {
    this.fetchPoints();
  },
  methods: {
    ...mapActions(['fetchPoints']),
    ...mapActions('profile', ['updateUserProfile']),
    ...mapMutations('profile', {
      resetProfileState: 'RESET_STATE',
      setPasswordModalVisible: 'SET_PROFILE_PASSWORD_MODAL',
    }),
    submitEdits() {
      this.formSubmitted = true;
      this.resetProfileState();
      if (this.formIsValid) {
        this.updateUserProfile({
          edits: {
            username: this.username,
            full_name: this.name,
          },
          session: this.session,
        });
      } else {
        if (this.nameIsInvalid) {
          this.$refs.name.focus();
        } else if (this.usernameIsInvalid) {
          this.$refs.username.focus();
        }
      }
    },
    getPermissionString(permission) {
      if (permission === 'can_manage_content') {
        return this.$tr('manageContent');
      }
      return permission;
    },
  },
};

